import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Select',
      component: () => import('../views/select/select.vue')
    },
    {
      path: '/:zoneid',
      redirect: 'display/:zoneid/home'
    },
    {
      path: '/display/:zoneid',
      name: 'Display',
      component: () => import('../views/display.vue'),
      children: [
        {
          path: 'home',
          name: 'Home',
          component: () => import('../views/home/home.vue')
        },
        {
          path: 'zone',
          name: 'Zone',
          component: () => import('../views/zone/zone.vue')
        },
        {
          path: 'space/:spaceindex',
          name: 'space',
          component: () => import('../views/space/space.vue')
        }
      ]
    }
  ]
});
