import Vue from 'vue';
import IdleVue from 'idle-vue';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import VueGtag from "vue-gtag";
import router from './config/Router';
import store from './config/Store';
import { defaultLocale, languages } from './languages/index';

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: "G-2BM7Z52W2L" }
});
 
Vue.use(IdleVue, { store,
  idleTime: 120000 });
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: Object.assign(languages)
});

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app');
