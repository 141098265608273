/* eslint-disable no-console */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    content: {
      'nl': null, 
      'de': null, 
      'en': null
    },
    zoneIdInit: null,
    zoneId: null,
    locale: 'nl'
  },
  getters: {
    content: state => {
      return state.content[state.locale] ? state.content[state.locale] : null
    },
    zoneId: state => {
      return state.zoneId-1
    },
    locale: state => {
      return state.locale
    }
  },
  mutations: {
    updateContent: (state, payload) => {
      state.content[payload.locale] = payload.data
    }
  }
});